*[hidden] {
    display: none !important;
}

:root {
    font-size: 16px;
    /* colors */
    --jo-primary: #f7941d;

    --jo-primary-light: #f7941d4d;

    --jo-secondary: #e65c33;

    --jo-secondary-light: #e65c334d;

    --jo-secondary-dark: #bc4a2b;

    --jo-accent: #00a79d;

    --jo-danger: #ff0000;

    --jo-danger-light: #ffcccc;

    --jo-success: forestgreen;

    --jo-success-light: #b2fcb2;

    --jo-text-default: #666766;

    --jo-accent-background-light: #f1f1f1;

    --jo-accent-background-dark: #ced4da;

    --jo-white: white;

    --jo-darkened-background: rgba(0, 0, 0, 0.65);

    /* other  */
    --jo-box-shadow-1: 0 0 3px rgb(0, 0, 0, 0.4);
    --jo-box-shadow-2: 0 0 5px rgb(0, 0, 0, 0.3);
    --jo-box-shadow-3: 0 0 4px var(--jo-accent-background-dark);
    --jo-wide-border-radius-1: 0.4rem;
    --jo-narrow-border-radius-1: 0.25rem;
    --jo-card-shadow-1: 0 0 4px rgb(0, 0, 0, 0.2);
    --jo-heavy-shadow-1: 0 0 10px rgb(0, 0, 0, 0.8);
    --jo-heavy-shadow-2: 0 0 6px rgb(0, 0, 0, 0.6);
    --jo-button-shadow-1: 0px 0px 4px rgb(0, 0, 0, 0.35);
    --jo-big-shadow-1: 0 10px 20px 5px rgb(0, 0, 0, 0.6);
    /* TODO we might want to give more specific names to these indexes...like highest, lowest, etc */
    --z-index-1: 1;
    --z-index-2: 2;
    --z-index-3: 3;
    /* padding */
    --padding-small: 0.25rem;
    --padding-medium: 0.5rem;
    --padding-large: 1rem;
}

html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-family: sans-serif;
}

div[hidden] {
    display: none;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
}

button {
    font-size: 1rem;
}

.jo-global--text-area {
    font-family: sans-serif;
    font-size: 0.9rem;
    color: rgb(70, 70, 70);
    font-weight: 200;
    padding: 0.25rem;
    margin-top: 0.25rem;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 3px;
    background-color: white;
    transition: 0.175s ease;
}

.jo-global--text-area:focus {
    box-shadow: 0 0 3px rgba(81, 203, 238, 1);
    outline: none;
    border: 1px solid rgb(165, 165, 165);
}

.jo-global--search-icon {
    background-color: var(--jo-accent-background-light);
    border: 1px solid #ced4da;
    padding: 0.25rem;
    border-radius: 2px;
    margin-right: -1px;
}

.jo-global--search-bar {
    border: 1px solid #ced4da;
    padding: 0.2rem 0.5rem;
    transition: 0.15s;
}

.jo-global--search-bar:focus {
    outline: none;
    border: 1px solid #c7d2dd;
    box-shadow: 0 0 3px rgba(81, 203, 238, 1);
}

.jo-global--small-date-input {
    padding: 0rem;
}

.jo-global--home-card-stat-line {
    box-sizing: border-box;
    border-top: 1px solid rgb(215, 215, 215);
    padding: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
}

.jo-global--home-card-stat-line:hover {
    background-color: var(--jo-accent-background-light);
}

.jo-global--font-family {
    font-family: sans-serif;
    color: rgb(70, 70, 70);
}

.jo-global--align-items-top {
    display: flex;
    align-items: flex-start;
}

.jo-global--align-items-top[hidden] {
    display: none;
}

.jo-global--align-items-bottom {
    display: flex;
    align-items: flex-end;
}

.jo-global--align-items-center {
    display: flex;
    align-items: center;
}

.jo-global--align-items-center[hidden] {
    display: none;
}

.jo-global--align-self-center {
    display: flex;
    align-self: center;
    align-items: center;
}

.jo-global--align-self-end {
    display: flex;
    align-items: center;
    align-self: end;
}

.jo-global--not-visible {
    visibility: hidden;
}

.jo-global--is-disabled:disabled {
    background-color: var(--jo-accent-background-light);
    opacity: 0.7;
}

/* TABLE STUFF */

.jo-global-table--main-container {
    font-family: sans-serif;
    color: rgb(80, 80, 80);
    font-weight: 200;
    width: 82.5%;
    background-color: white;
    box-sizing: border-box;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 3px rgb(0, 0, 0, 0.4);
    margin-bottom: 1rem;
}

.jo-global-table--upper-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.jo-global-table--search-container {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
}

.jo-global-table--table {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 0.5fr repeat(5, 1fr) 0.75fr;
}

.jo-global-table--row {
    display: contents;
}

.jo-global-table--row:hover > div {
    background-color: var(--jo-primary-light);
}

.jo-global-table--row-item {
    box-sizing: border-box;
    padding: 0.5rem 0rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
}

.jo-global-table--header-row {
    display: contents;
}

.jo-global-table--header-row-item {
    box-sizing: border-box;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.jo-global-table--line-break {
    background-color: rgb(0, 0, 0, 0.15);
    margin-bottom: 0px;
}

.jo-global-table--header-title {
    box-sizing: border-box;
    margin: 0;
}

.jo-global-table--page-numbers-container {
    box-sizing: border-box;
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.jo-global-table--page-number {
    box-sizing: border-box;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--jo-accent-background-dark);
    border-radius: 2px;
    color: var(--jo-primary);
    margin-left: -1px;
}

.jo-global-table--page-number:hover {
    background-color: rgb(0, 0, 0, 0.07);
    cursor: pointer;
}

.jo-global-table--page-number-selected {
    background-color: var(--jo-primary);
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.3);
    border: 1px solid var(--jo-primary);
    color: white;
    transform: scale(1.05);
    font-weight: bold;
}

.jo-global-table--page-number-selected:hover {
    background-color: var(--jo-primary);
}

.jo-global-table--actions {
    display: flex;
}

.jo-global-table--action {
    padding-right: 0.25rem;
}

/* END TABLE STUFF */

/*BootStrap Copy Cats*/
.jo-global--card {
    box-sizing: border-box;
    background-color: white;
    border-radius: var(--jo-wide-border-radius-1);
    box-shadow: var(--jo-card-shadow-1);
    padding: 1rem;
}
@media screen and (max-width: 1200px) {
    .jo-global--card {
        padding: 0;
    }
}
.jo-global--emptyIconContainer {
    color: var(--jo-accent-background-light);
    text-align: center;
}

.flex-container {
    display: flex;
    box-sizing: border-box;
}

.flex-row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.flex-col {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.f1 {
    flex: 1;
}
.f1-5 {
    flex: 1.5;
}
.f2 {
    flex: 2;
}
.f3 {
    flex: 3;
}
.f4 {
    flex: 4;
}
.f5 {
    flex: 5;
}
.f6 {
    flex: 6;
}
.f7 {
    flex: 7;
}
.f8 {
    flex: 8;
}

/* START COMPANY DETAILS */

.jo-company-details--info-card {
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 0 3px rgb(0, 0, 0, 0.4);
    background-color: white;
    border-radius: 6px;
    padding: 1rem;
}

.jo-company-details--info-card-header-container {
    box-sizing: border-box;
    padding: 0.5rem;
}

.jo-company-details--info-card-header {
    margin: 0rem;
    color: rgb(0, 0, 0, 0.6);
    box-sizing: border-box;
}

.jo-company-details--info-card-body-content {
    box-sizing: border-box;
    padding: 0 1rem 2rem;
    width: 100%;
}

.jo-company-details--info-card-row {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: space-between;
    margin-top: 1.25rem;
}
@media screen and (max-width: 1000px) {
    .jo-company-details--info-card-row {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: space-between;
        margin-top: 1.25rem;
    }
}

.jo-company-details--info-card-row input[type='text'] {
    width: 85%;
}

.jo-company-details--info-card-row select {
    width: 89%;
}

.jo-company-details--textarea-row {
    width: 100%;
    margin-top: 1.7rem;
}

.jo-company-details--alerts-expiration-date-container {
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1.8rem;
    margin-top: -0.2rem;
}

.jo-company-details--hidden-input {
    margin-top: 0.7rem;
}

/* END COMPANY DETAILS */

/* BUTTONS */

.jo-global--action-button-1 {
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: var(--jo-primary);
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 2px;
    box-shadow: var(--jo-button-shadow-1);
    margin-right: 0rem;
    text-decoration: none;
}

.jo-global--action-button-1:hover {
    box-shadow: 0px 0px 1px rgb(0, 0, 0, 0.35);
    background-color: #f8b064;
    text-decoration: none;
}

.jo-global--action-button-1:active {
    background-color: #e09f58;
    text-decoration: none;
}

.jo-global--action-button-1:visited {
    text-decoration: none;
}

/* action button green */

.jo-global--action-button-green {
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: forestgreen;
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 2px;
    box-shadow: var(--jo-button-shadow-1);
    margin-right: 0rem;
    text-decoration: none;
}

.jo-global--action-button-green:hover {
    box-shadow: 0px 0px 1px rgb(0, 0, 0, 0.35);
    background-color: rgb(51, 179, 51);
    text-decoration: none;
}

.jo-global--action-button-green:active {
    background-color: rgb(73, 148, 73);
    text-decoration: none;
}

.jo-global--action-button-green:visited {
    text-decoration: none;
}

.jo-global--action-button-green-2 {
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: forestgreen;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 2px;
    box-shadow: var(--jo-button-shadow-1);
    margin-right: 0rem;
    text-decoration: none;
}

.jo-global--action-button-green-2:hover {
    box-shadow: 0px 0px 1px rgb(0, 0, 0, 0.35);
    background-color: rgb(51, 179, 51);
    text-decoration: none;
}

.jo-global--action-button-green-2:active {
    background-color: rgb(73, 148, 73);
    text-decoration: none;
}

.jo-global--action-button-green-2:visited {
    text-decoration: none;
}

/* end action button green */

.jo-global--secondary-button-1 {
    box-sizing: border-box;
    outline: none;
    border: none;
    background-color: var(--jo-accent-background-light);
    padding: 0.5rem 1.75rem;
    border-radius: 2px;
    box-shadow: 0px 0px 4px rgb(0, 0, 0, 0.35);
    text-decoration: none;
}

.jo-global--secondary-button-1:hover {
    background-color: #e0e0e0;
    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.35);
    text-decoration: none;
}

.jo-global--secondary-button-1:active {
    background-color: var(--jo-accent-background-light);
    text-decoration: none;
}

.jo-global--secondary-button-1:visited {
    text-decoration: none;
}

.jo-global--secondary-button-2 {
    box-sizing: border-box;
    font-size: 0.9rem;
    background-color: var(--jo-accent-background-light);
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);
    text-decoration: none;
    outline: none;
    border: none;
    white-space: nowrap;
}

.jo-global--secondary-button-2:hover {
    background-color: #e0e0e0;
    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.35);
    text-decoration: none;
}

.jo-global--secondary-button-2:active {
    background-color: #df652d;
    text-decoration: none;
}

.jo-global--secondary-button-2:visited {
    text-decoration: none;
}

.jo-global--action-button-2 {
    box-sizing: border-box;
    font-size: 0.9rem;
    color: white;
    background-color: var(--jo-secondary);
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);
    text-decoration: none;
    outline: none;
    border: none;
    white-space: nowrap;
}

.jo-global--action-button-2:hover {
    box-shadow: none;
    background-color: var(--jo-secondary-light);
    text-decoration: none;
}

.jo-global--action-button-2:active {
    background-color: #df652d;
    text-decoration: none;
}

.jo-global--action-button-2:visited {
    text-decoration: none;
}

.jo-global--delete-button-1 {
    box-sizing: border-box;
    outline: none;
    border: none;
    background-color: red;
    color: white;
    padding: 0.5rem 1.75rem;
    border-radius: 2px;
    box-shadow: var(--jo-button-shadow-1);
}

.jo-global--delete-button-1:hover {
    background-color: rgb(250, 121, 121);
    box-shadow: none;
}

.jo-global--delete-button-1:active {
    background-color: rgb(204, 79, 79);
}

.jo-global--delete-button-2 {
    box-sizing: border-box;
    font-size: 0.9rem;
    outline: none;
    border: none;
    background-color: red;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 2px;
    box-shadow: var(--jo-button-shadow-1);
}

.jo-global--delete-button-2:hover {
    background-color: rgb(250, 121, 121);
    box-shadow: none;
}

.jo-global--delete-button-2:active {
    background-color: rgb(204, 79, 79);
}

.jo-global--action-icon {
    color: var(--jo-primary);
    border: 1px solid var(--jo-primary);
    font-size: 20px;
    transition: 0.15s ease;
    padding: 0.1rem 0.3rem;
    text-decoration: none;
    background-color: transparent;
}

.jo-global--action-icon:hover {
    color: white;
    background-color: var(--jo-primary);
    cursor: pointer;
}

.jo-global--action-icon:active {
    outline: none;
}

.jo-global--login-button {
    font-size: 16px;
    padding: 8px 20px;
    background-color: var(--jo-primary);
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    outline-color: var(--jo-primary);
}

.jo-global--login-button:hover {
    box-shadow: 0px 0px 5px var(--jo-text-default);
}

.jo-login--input-container {
    padding-top: 8px;
    padding-bottom: 8px;
}

.jo-global--login-button-container {
    padding-top: 8px;
}

.jo-global--alert-button {
    border-radius: 4px;
    margin-left: 1.2em;
    font-size: 16px;
    padding: 8px 20px;
    cursor: pointer;
    border: none;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.24);
    outline: none;
    box-sizing: border-box;
}

.jo-global--alert-button-fill {
    background: var(--jo-primary);
    color: white;
}

.jo-global--alert-button:hover {
    box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.24);
    background: var(--jo-primary);
    transform: scale(1.06);
}

.jo-global--alert-button:active {
    box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.24);
    background: var(--jo-secondary);
    transform: scale(1.02);
}

.jo-global--alert-button-cancel:hover {
    background: rgb(0, 0, 0, 0.025);
}

.jo-global--alert-button-cancel:focus {
    background: rgb(0, 0, 0, 0.05);
}

/* END BUTTONS */

.jo-global--underlined {
    text-decoration: underline;
}

.jo-global--input {
    font-family: sans-serif;
    font-size: 0.9rem;
    color: rgb(60, 60, 60);
    padding: 0.3rem;
    /* margin-top: 0.25rem; */
    border: 1px solid rgb(180, 180, 180);
    border-radius: 3px;
    background-color: white;
    transition: 0.175s ease;
}

.jo-global--input:focus {
    outline: none;
    box-shadow: 0 0 3px rgba(81, 203, 238, 1);
    border: 1px solid #9897b4;
}

.jo-global--date-input {
    font-family: sans-serif;
    font-size: 0.9rem;
    color: rgb(60, 60, 60);
    padding: 0.3rem;
    margin-top: 0.25rem;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 3px;
    background-color: white;
    transition: 0.175s ease;
    display: block;
}

.jo-global--date-input:focus {
    outline: none;
    box-shadow: 0 0 3px rgba(81, 203, 238, 1);
    border: 1px solid #9897b4;
}

.jo-global--hide-scrollbar::-webkit-scrollbar {
    display: none; /*hide scroll bar chrome, safari, edge*/
}

.jo-global--hide-scrollbar {
    scrollbar-width: none; /*hide it for firefox*/
}

.jo-global--high-level-statses-csv-button-container {
    box-sizing: border-box;
    padding-bottom: 2rem;
}

.jo-global--1rem-above {
    box-sizing: border-box;
    padding-top: 1rem;
}

.jo-global--text-align-center {
    text-align: center;
}

.jo-global--card-title {
    font-family: sans-serif;
    font-weight: bold;
    color: var(--jo-text-default);
}

.jo-global--action-item-preview-container {
    padding: 1rem;
    width: 600px;
    background-color: var(--jo-accent-background-light);
    border-radius: var(--jo-narrow-border-radius-1);
}

.jo-global--slight-padding-right-1 {
    padding-right: 0.5rem;
}

.jo-global--input {
    font-family: sans-serif;
    font-size: 0.9rem;
    color: rgb(60, 60, 60);
    padding: 0.3rem;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 3px;
    background-color: white;
    transition: 0.175s ease;
    box-sizing: border-box;
    width: 100%;
}

.jo-global--width-50-vw {
    width: 50vw;
}

.jo-global--padding-right-1-rem {
    padding-right: 1rem;
}

.jo-global--pointer {
    cursor: pointer;
}

@media screen and (max-width: 1600px) {
    button {
        font-size: calc(0.5rem + 1vmin);
    }
    .jo-global--action-button-1,
    .jo-global--delete-button-1,
    .jo-global--action-button-green,
    .jo-global--secondary-button-1 {
        padding: 0.5rem 1rem;
    }
    .jo-global--action-icon {
        font-size: calc(10px + 1vmin) !important;
        padding: 0.1rem 0.1rem;
    }
}

@media screen and (min-width: 1600px) {
    .jo-global--action-button-2,
    .jo-global--secondary-button-2,
    .jo-global--delete-button-2,
    .jo-global--action-button-green-2 {
        font-size: 1rem;
    }
}

.jo-global--center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-bottom-filler {
    height: 5rem;
    width: 100%;
}

.jo-global--cursor-help {
    cursor: help;
}
